@tailwind base;

@tailwind components;

@font-face {
    font-family: 'Raleway';
    src: local('Raleway'), url(./fonts/Raleway-Regular.ttf) format('ttf');
  }

@tailwind utilities;
